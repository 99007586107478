import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Heading",
  "type": "Typography"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Heading`}</strong>{` is used for rendering text headings around the application. It's part of
the typography category of components which all use
`}<a parentName="p" {...{
        "href": "https://fonts.google.com/specimen/Source+Sans+Pro"
      }}>{`Source Sans Pro`}</a>{` as a font. The
variations of the `}<strong parentName="p">{`Heading`}</strong>{` component follow the design system and are logically
divided into different levels.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The "Source Sans Pro" font will not be included by the UI library, so it needs to be
added separately to the project.`}</p>
    </blockquote>
    <hr></hr>
    <h2>{`Example`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true editor=false",
        "live": "true",
        "editor": "false"
      }}>{`<Heading level="1">Level one heading</Heading>
`}</code></pre>
    <h2>{`Props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`level`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"1" ... "4"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`"1"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The heading level`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The content of the heading`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Variations`}</h2>
    <p><strong parentName="p">{`Heading`}</strong>{` level 4 is best used as a navigation or section subtitle, since it represents
best as a content separator.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true",
        "live": "true"
      }}>{`<>
  <Heading level="1">h1. Heading</Heading>
  <Divider />
  <Heading level="2">h2. Heading</Heading>
  <Divider />
  <Heading level="3">h3. Heading</Heading>
  <Divider />
  <Heading level="4">h4. Heading</Heading>
</>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      